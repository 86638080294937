.button_slide {
    color: #FFF;
    display: inline-block;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 rgb(124, 58, 222);
    -webkit-transition: ease-out 0.5s;
    -moz-transition: ease-out 0.5s;
    transition: ease-out 0.5s;
    transition: ease-in 0.9s;
    position: relative;
  }

  .slide_right:hover {
    box-shadow: inset 400px 0 0 0 rgb(38, 0, 255);
  }